import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { FloatClasses, FloatResponsive } from "../../examples/utilities/Float";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 id="overview" className="h2 font-weight-bold mt-3">
  Overview
  <a className="header-link text-light text-decoration-none font-weight-normal" href="#overview" aria-label="anchor"></a>
    </h3>
    <p>
  These utility classes float an element to the left or right, or disable
  floating, based on the current viewport size using the CSS float property.{" "}
  <code>!important</code> is included to avoid specificity issues. These use the
  same viewport breakpoints as our grid system. Please be aware float utilities
  have no affect on flex items.
    </p>
    <h3 id="classes" className="h2 font-weight-bold mt-3">
  Classes
  <a className="header-link text-light text-decoration-none font-weight-normal" href="#classes" aria-label="anchor"></a>
    </h3>
    <CodeBlock code={FloatClasses} mdxType="CodeBlock">Toggle a float with a class:</CodeBlock>
    <h3 id="responsive" className="h2 font-weight-bold mt-3">
  Responsive
  <a className="header-link text-light text-decoration-none font-weight-normal" href="#responsive" aria-label="anchor"></a>
    </h3>
    <CodeBlock code={FloatResponsive} mdxType="CodeBlock">
  Responsive variations also exist for each <code>float</code> value.
    </CodeBlock>
    <p>Here are all the support classes:</p>
    <ul>
  <li>
    <code>.float-left</code>
  </li>
  <li>
    <code>.float-right</code>
  </li>
  <li>
    <code>.float-none</code>
  </li>
  <li>
    <code>.float-sm-left</code>
  </li>
  <li>
    <code>.float-sm-right</code>
  </li>
  <li>
    <code>.float-sm-none</code>
  </li>
  <li>
    <code>.float-md-left</code>
  </li>
  <li>
    <code>.float-md-right</code>
  </li>
  <li>
    <code>.float-md-none</code>
  </li>
  <li>
    <code>.float-lg-left</code>
  </li>
  <li>
    <code>.float-lg-right</code>
  </li>
  <li>
    <code>.float-lg-none</code>
  </li>
  <li>
    <code>.float-xl-left</code>
  </li>
  <li>
    <code>.float-xl-right</code>
  </li>
  <li>
    <code>.float-xl-none</code>
  </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      